<template>
	<b-card-group>
		<b-card>
			<b-row>
				<b-col>
					<h4 class="mb-4">
						{{ translate('all_credit_cards') }}
					</h4>
				</b-col>
				<b-col cols="2">
					<b-button
						:to="{name: 'NewProfileCreditCard'}"
						variant="primary"
						class="float-right">
						{{ translate('new_credit_card') }}
					</b-button>
				</b-col>
			</b-row>
			<hr>
			<h5 class="text-muted">
				{{ translate('recurring_card') }}
			</h5>
			<credit-card-grid
				:loading="creditCardLoading"
				:data="recurring"
				:show-select="false"
				:show-backup-disclaimer="showBackupDisclaimer"
				prefix="d"
				is-default
				@setDefault="setDefaultCreditCard"
				@setFirstDefault="setFirstDefaultCard"
				@delete="deleteCreditCard"
				@edit="editCreditCard" />
			<hr>
			<h5 class="text-muted">
				{{ translate('additional_credit_cards') }}
			</h5>
			<credit-card-grid
				:loading="creditCardLoading"
				:data="additional"
				:show-select="false"
				:show-disclaimer="false"
				show-default
				@setDefault="setDefaultCreditCard"
				@delete="deleteCreditCard"
				@edit="editCreditCard" />
		</b-card>
	</b-card-group>
</template>
<script>

import {
	Profile, Validations, Users, Alerts,
} from '@/translations';
import CreditCard from '@/util/CreditCard';
import CreditCardGrid from './components/CreditCardGrid.vue';
import EventBus from '@/util/eventBus';

export default {
	name: 'AllCreditCard',
	components: { CreditCardGrid },
	messages: [Profile, Validations, Users, Alerts],
	data() {
		return {
			alert: new this.$Alert(),
			creditCard: new CreditCard(),
			creditCardDefault: new CreditCard(),
			additional: [],
			recurring: [],
			tabIndex: 0,
			showBackupDisclaimer: false,
			tabs: [
				{
					name: 'CreditCard',
					translate_key: 'credit_card',
				},
				{
					name: 'CreditCardBackup',
					translate_key: 'credit_card_backups',
				},
			],
		};
	},
	computed: {
		creditCardLoading() {
			return !!this.creditCard.data.loading;
		},
		creditCardErrors() {
			return this.creditCard.data.errors;
		},
		creditCardData() {
			try {
				const { data } = this.creditCard.data.response.data;
				if (data === undefined) {
					return [];
				}
				return data;
			} catch (error) {
				return [];
			}
		},
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			this.creditCard.getCreditCards(this.$user.details().id).then(() => {
				this.transformData();
			});
		},
		setDefaultCreditCard(creditCardID) {
			this.creditCardDefault.setDefaultCreditCard(this.$user.details().id, creditCardID).then(() => {
				this.getData();
				this.alert.toast('success', this.translate('credit_card_set_default'));
			}).catch((error) => {
				if (error.messageError) {
					this.alert.toast('error', error.messageError);
				} else {
					this.alert.toast('error', this.translate('something_went_wrong'));
				}
			});
		},
		setFirstDefaultCard() {
			this.creditCardDefault.setBackupCardAsDefault(this.$user.details().id).then(() => {
				this.getData();
				this.alert.toast('success', this.translate('credit_card_set_default'));
			}).catch(() => {
				this.alert.toast('error', this.translate('something_went_wrong'));
			}).finally(() => {
				EventBus.$emit('updateAccountSteps');
			});
		},
		deleteCreditCard(creditCardID) {
			const trans = {
				title: this.translate('delete_credit_card_title'),
				text: this.translate('delete_credit_card_text'),
			};
			const config = {
				confirmButtonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
			};
			this.alert.confirmation(trans.title, trans.text, config, false).then(() => {
				this.creditCard.deleteCreditCard(this.$user.details().id, creditCardID).then(() => {
					this.alert.toast('success', this.translate('credit_card_deleted'));
					this.getData();
				}).catch(() => {
					this.alert.toast('error', this.translate('something_went_wrong'));
				});
			}).catch(() => {}).finally(() => {
				EventBus.$emit('updateAccountSteps');
			});
		},
		editCreditCard(creditCardID) {
			this.$router.push({ name: 'UpdateProfileCreditCard', params: { credit_card_id: creditCardID } });
		},
		transformData() {
			this.recurring = [];
			this.additional = [];
			this.creditCardData.forEach((item) => {
				if (item.attributes.backup_card) {
					this.showBackupDisclaimer = true;
				}

				if (!item.attributes.is_default) {
					this.additional.push(item);
				} else if (item.attributes.is_default && Object.keys(item).length) {
					this.recurring.push(item);
				}
			});
			EventBus.$emit('updateAccountSteps');
		},
	},
};
</script>
